/**
 * @module SalesFlow/view
 */

declare var $: JQueryStatic;

import Injector from 'core/injector';

import {Renderable} from '../../renderable';

import Step from '../../view/shared/step';
import { Constants } from 'core/constants';

/**
 * Check div#sales-flow-stepper (rendered by middleman or opentext) and resolve current step
 * While sales flow is dispatched based on current step, this class is not part of frontend
 *
 * Animates, too
 */
export default class Stepper extends Renderable<string> {

    private _element: JQuery;

    protected _currentStep: string;
    private _previousStep: string;

    protected _stepNames: string[] = [];

    constructor (injector: Injector) {

        super(injector);

        this._element = $('#nsf-stepper');

        /**
         * Before setting the new active step, set the previous from value of the storage var
         */
        const storage = injector.getStorage();
        this._previousStep = storage.getItem('lastStep');
        storage.setItem('lastStep', '' + this.getInjector().getRouting().getCurrentPage());

        this._currentStep = this.getInjector().getRouting().getCurrentPage();

    }

    get previousStep (): string {
        return this._previousStep;
    }

    /**
     * CO-20535-adjustment-of-nsf-stepper:
     * Stepper URL for "Smartphones" step differs while we're in transition between simplicity and kiss
     */
    private getDeviceOverviewURL (): string {

        const btx = this.getInjector().getBtx();
        const salesChannel = this.getInjector().getFlowState().getSalesChannel();
        const isGigakombi = this._injector.getGigakombi().isGigakombi();
        const orderType = this.getInjector().getFlowStateWithSalesChannel().getOrderType();

        /**
         * lastDisplayedDeviceOverview is set via CMS Contentclass
         * .x - Simplicity: Sonderlösungen => LastDisplayedDeviceOverview
         * Instances are in new KISS-Page (old Smartphone-Overview as long as we have a A/B test
         * and on copy of Smartphone-Overview
         */
        const lastDisplayedDeviceOverview = this._injector.getStorage().getItem('lastDisplayedDeviceOverview');

        /**
         * Friends & Family is always Friends & Family NSF URL
         */
        if (Constants.SALESCHANNEL_FAMILY_FRIENDS === salesChannel) {
            return this.getInjector().getOptions().get('alle_smartphones_fuf_url_nsf');
        }

        /**
         * Gigakombi is always regular NSF URL
         */
        if (true === isGigakombi) {
            return this.getInjector().getOptions().get('alle_smartphones_url_nsf');
        }

        /**
         * if current step is device overview by itself, of course it's the NSF page
         */
        if ('device_overview' === this._currentStep) {
            return this.getInjector().getOptions().get('alle_smartphones_url_nsf');
        }

        /**
         * only needs to be applied for BNT
         */
        if (Constants.BTX_BNT !== btx ) {
            return this.getInjector().getOptions().get('device_overview_url');
        }

        /**
         * from here on it's getting kind of interesting
         */
        if (undefined === lastDisplayedDeviceOverview) {
            /**
             * here, none of the device overview pages has been called before
             */
            if (Constants.SALESCHANNEL_CONSUMER === salesChannel || Constants.SALESCHANNEL_YOUNG === salesChannel) {
                return this.getInjector().getOptions().get('alle_smartphones_url_kiss');
            } else {
                return this.getInjector().getOptions().get('alle_smartphones_url_nsf');
            }
        } else if ('KISS' === lastDisplayedDeviceOverview) {
            /**
             * if the user has been on a KISS page before, then it's always the KISS device overview
             * no matter which salesChannel we have. That's how the Squad requested.
             */
            return this.getInjector().getOptions().get('alle_smartphones_url_kiss');
        } else {
            /**
             * This only applies for consumer or young:
             * if the last visited page is the NSF page, we check wether the KISS page was called before.
             * we can detect this by checking the session storage: the KISS page sets SAILS storage
             */
            if (Constants.SALESCHANNEL_CONSUMER === salesChannel || Constants.SALESCHANNEL_YOUNG === salesChannel) {
                const sailsSessionStorage = this._injector.getStorage().getItem('SAILS');
                const kissSessionStorage = this._injector.getStorage().getItem('KISS_MARKER');
                if (null === kissSessionStorage && null === sailsSessionStorage) {
                    // KISS page was never called before
                    return this.getInjector().getOptions().get('alle_smartphones_url_nsf');
                } else {
                    // KISS page was called before
                    return this.getInjector().getOptions().get('alle_smartphones_url_kiss');
                }
            } else {
                /**
                 * soho will always be lead to NSF
                 */
                return this.getInjector().getOptions().get('alle_smartphones_url_nsf');
            }

        }

        /**
         * Fallback!
         */
        return this.getInjector().getOptions().get('device_overview_url');
    }

    /**
     * CO-20535-adjustment-of-nsf-stepper
     * Breadcrumb URL for "Smartphones" step differs while we're in transition between simplicity and kiss
     */
    private adjustBreadcrumb (): void {

        /**
         * KISS-URL: https://eweb.vfd2-testnet.de/privat/handys-tablets-tarife/alle-smartphones.html
         * COPY-URL: https://eweb.vfd2-testnet.de/privat/smartphones/alle-smartphones.html
         *
         * Usually, KISS URL is included in Breadcrumb. When we're in Soho-Flow, we should
         * change this URL to the copied page
         */

        const newBreadcrumbURL = this.getDeviceOverviewURL(); // could be KISS URL or copie-URL
        const kissURL = this.getInjector().getOptions().get('alle_smartphones_url_kiss');

        if (kissURL !== newBreadcrumbURL) {
            const breadcrumb = document.getElementsByClassName('mod-breadcrumb')[0];

            if (breadcrumb) {
                breadcrumb.innerHTML = breadcrumb.innerHTML.replace(kissURL, newBreadcrumbURL);
            }

        }

    }

    protected setDeviceDetailUrl (url: string) {

        this._element.find('a[data-name="device_detail"]').attr('href', url);

    }

    protected getData (): { steps: Step[], basket: Step } {

        let activeIndex: number|null = null;
        // @TODO: Reevalute after refactoring routing for reco page. wasOnRecommendationPage should not be set to the storage anymore.
        if (!this.getInjector().getStorage().hasItem('wasOnRecommendationPage') || this.getInjector().getStorage().getItem('wasOnRecommendationPage') !== 'true') {
            this._stepNames = this._stepNames.filter((name, index) => {
                return name !== 'vvl_recommendation';
            });
        }

        const steps: Step[] = this._stepNames.map((name, index) => {

            /**
             * usually, the name of the step is like 'device_overview'
             */
            const text = this.getInjector().getStrings().get('stepper.' + name);

            let href = this.getInjector().getOptions().get(name + '_url');

            /**
             * specials for detecting href
             */
            switch (name) {
                case 'device_detail':
                    href = this.getInjector().getOptions().get(name + '_prefix');
                    break;
                case 'device_overview':
                    href = this.getDeviceOverviewURL();
                    break;
            }

            let status;
            if (this._currentStep === this._stepNames[index]) {
                activeIndex = index;
                status = 'active';
            }

            if (activeIndex !== null && activeIndex < index) {
                status = 'inactive';
            }

            if (activeIndex > index || activeIndex === null) {
                status = 'completed';
            }

            return new Step(name, text, href, status, index + 1);

        });

        const text = this.getInjector().getStrings().get('stepper.basket');
        const href = this.getInjector().getOptions().get('basket_url');
        let active = 'inactive';
        if (this._currentStep === 'basket') {
            active = 'active';
        }
        const basket = new Step('basket', text, href, active, this._stepNames.length + 1);

        return {steps: steps, basket: basket};

    }

    public render (exposed = ''): void {

        const data = this.getData();

        this._element.addClass(this._currentStep);

        // Adding custom Css Class to stepper via options
        const stepperClass = this.getInjector().getOptions().get('stepper_class');
        this._element.addClass(stepperClass);

        this._element.html(this.getInjector().getTemplates().render('stepper', data));

        if (false === this.getInjector().getStorage().canPersist()) {

            this._element.before(this.getInjector().getTemplates().render('cookie_warning', {}));

        }

        /**
         * CO-20535-adjustment-of-nsf-stepper
         */
        this.adjustBreadcrumb();

    }

    public events (): void {

        // make inactive links unclickable
        this._element.find('a').on('click', (evt: JQueryEventObject) => {
            const myColor = $(evt.target).css('color');
            if ((true === $(evt.target).parent().hasClass('inactive')) || ('rgb(204, 204, 204)' === myColor) || ('#CCCCCC' === myColor) || ('#cccccc' === myColor)) {
                evt.preventDefault();

                return;

            }
        });

    }

    /**
     * For Simonly we use the connectmore/x-sell page to
     */
    public updateStepperForSimonlyUnlimitedWorkaround () {

        this._element.addClass('unlimited-simonly');

    }

}
